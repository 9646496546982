<!--
 * @Author: your name
 * @Date: 2021-06-24 14:16:04
 * @LastEditTime: 2021-07-23 18:07:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \education_resource\src\components\jump\index.vue
-->
<template>
  <div class="jump-container d-f a-c">
    <div class="logo">
      <img src="@/assets/image/jump_logo.png" alt="" />
    </div>
    <div class="menu-box">
      <a-menu :selectedKeys="[this.$route.fullPath]" mode="horizontal" @click="onChange">
        <a-menu-item key="/index">
          <router-link to="/index">首页</router-link>
        </a-menu-item>
        <a-menu-item key="/secondary-details?type=test-questions"> 试题天地 </a-menu-item>
        <a-menu-item key="/secondary-details?type=teaching-plan"> 教案频道 </a-menu-item>
        <a-menu-item key="/secondary-details?type=ppt"> ppt课件 </a-menu-item>
        <a-menu-item key="/secondary-details?type=teaching-material">
          教材世界
        </a-menu-item>
        <a-menu-item key="/secondary-details?type=video"> 视频专区 </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {};
  },
  methods: {
    onChange(e) {
      if (e.key != "/index") {
        let route = e.key;
        let index = e.key.indexOf("=");
        let identifier = e.key.slice(index + 1);
        let navList = this.$ls.get("navList");
        this.$ls.set("identifier", identifier, 60 * 60 * 1000 * 24);
        let navOneId = navList.find((r) => r.identifier === identifier).id;
        this.$ls.set("navOneId", navOneId, 60 * 60 * 1000 * 24);
        this.$ls.set("subjectId", null);
        this.$ls.set("gradeId", null);
        this.$ls.set("search", null);
        this.$router.push(route);
        this.reload();
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.jump-container {
  width: 100%;
  height: 84px;
  padding: 0 120px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 41, 15, 0.14);
  .logo {
    img {
      width: 156px;
    }
  }
  .menu-box {
    width: 800px;
    margin-left: 100px;
    .ant-menu {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
    }
    .ant-menu-item {
      border-bottom-color: transparent;
    }
    .ant-menu-item-selected {
      border-bottom-color: transparent;
    }
    .ant-menu-item-active {
      border-bottom-color: transparent;
    }
    .ant-menu-horizontal {
      border-bottom-color: transparent;
    }
    .ant-menu-overflowed-submenu {
      border-bottom-color: transparent;
    }
  }
}
</style>
